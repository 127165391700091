<template>
  <div class="prices-info__suggested-sale-price">
    <s-popover
      v-if="showRetailPricePopover"
      v-model="popoverActive"
      :content="pricePopoverLang"
      :placemen="popoverPlacemen"
      trigger-type="user"
      show-close-icon
      
      :prop-style="{
        maxWidth: '6.4rem',
        zIndex: '999999',
        transform: 'translate3d(0px, 0px, 999999px)'
      }"
    >
      <div 
        slot="reference"
        class="suggested-popover" 
        @click.stop="popoverClick"
        @touchstart.stop="popoverClickTouchstart"
      >
        <SuggestedSalePrice
          :value="priceValue"
          :labelText="lowestPriceLabelLang"
          :data-note="supportVueDebug ? '合规价 取suggestedSalePrice' : ''"
        />
        <i 
          ref="iconCaution"
          class="icon-suggested-caution sh_m_sui_icon_caution_12px_2"
          :data-id="id"
          :style="{
            opacity: showIconSuggested ? 1 : 0
          }"
        ></i>
      </div>
    </s-popover>
    <SuggestedSalePrice
      v-else
      :value="priceValue"
      :labelText="lowestPriceLabelLang"
      :data-note="supportVueDebug ? '合规价 取suggestedSalePrice' : ''"
    />
  </div>
</template>

<script setup name="SuggestedSalePriceContainer">
import SuggestedSalePrice from './SuggestedSalePrice.vue'
import { Popover as SPopover } from '@shein/sui-mobile'

import { ref, toRefs, watch, nextTick, onMounted } from 'vue'
import { isFrPlSuggestedSalePrice, isDeSuggestedSalePrice, isSpecialSuggestedSalePrice } from '../../js/priceDisplayTheResults'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {},
  },
  specialData: {
    type: Object,
    default: () => {},
  },
  supportVueDebug: {
    type: Boolean,
    default: false,
  }
})

const { goodsInfo, specialData } = toRefs(props)
const { retailPrice } = goodsInfo.value

const suggestedSalePriceType = specialData.value?.type

const isSpecialSuggested = isSpecialSuggestedSalePrice(suggestedSalePriceType)
const isFrPl = isFrPlSuggestedSalePrice(suggestedSalePriceType)
const isDe = isDeSuggestedSalePrice(suggestedSalePriceType)

const suggestedSalePriceInfo = goodsInfo.value?.suggestedSalePrice

const lowestPriceLabelLang = isSpecialSuggested ? specialData.value?.lowestPriceLabelLang : ''

const pricePopoverLang = isDe ? specialData.value?.pricePopoverLang : ''

// 德国展示原价  法国挪威展示合规价
const priceValue = isFrPl ? suggestedSalePriceInfo : retailPrice

const showRetailPricePopover = specialData.value?.showRetailPricePopover

// 唯一id
const id = ref('')
// 是否激活pop弹窗
const popoverActive = ref(false)
// popover位置
const  popoverPlacemen = ref('bottom')

// 是否展示icon
const iconCaution = ref(null)
const showIconSuggested = ref(false)


const popoverClick = (e) => {
  if (window?.deSuggestedSaleComponnet?.lock) return false

  if (!popoverActive.value) {
    const { clientY } = e
    const windowHieght = window.innerHeight
    const remainingHeight  = windowHieght - clientY
    
    // hack fix bug
    // 需要在打开找相似推荐时，去掉 body style 中的 top 如果有的话！避免该 top 影响 tooltip 的位置
    if (document.body.style.top) {
      document.body.style.top = ''
    }

    // 根据底部剩余宽度计算popover位置
    popoverPlacemen.value = remainingHeight > 100 ? 'bottom' : 'top'
    
    nextTick(() => {
      popoverActive.value = true
      window.deSuggestedSaleComponnet.popoverActiveId.value = id.value
    })
  } else {
    window.deSuggestedSaleComponnet.popoverActiveId.value = ''
  }
}

const popoverClickTouchstart = (e) => {
  if (window?.deSuggestedSaleComponnet?.lock) return false

  if (window.deSuggestedSaleComponnet?.popoverActiveId?.value) {
    window.deSuggestedSaleComponnet.popoverActiveId.value = ''

    window.deSuggestedSaleComponnet.lock = true

    setTimeout(() => {
      window.deSuggestedSaleComponnet.lock = false
    }, 500)
  }
}

onMounted(() => {
  const { goods_id, goods_sn } = goodsInfo.value
  id.value = `${goods_id}_${goods_sn}`

  const handleFun = () => {
    if (!showRetailPricePopover) return false
    
    // 判断icon是否够位置展示
    // 没有被压缩变形，宽度高度一致，证明可以展示icon
    const { width, height } = iconCaution.value?.getBoundingClientRect()
    showIconSuggested.value = width >= height
    

    const touchHandler = (e) => {
      const { target } = e

      const popoverActiveId = window.deSuggestedSaleComponnet.popoverActiveId.value
      if (popoverActiveId && !target.className.includes('S-popover__main')) {
        const parentElement = target.parentElement.parentElement
        const popoverElement = parentElement.querySelector('.icon-suggested-caution')
        const popoverId = popoverElement?.getAttribute('data-id')
        if (popoverId === popoverActiveId) return false
    
        window.deSuggestedSaleComponnet.popoverActiveId.value = ''
        window.deSuggestedSaleComponnet.lock = true

        setTimeout(() => {
          window.deSuggestedSaleComponnet.lock = false
        }, 500)
      }
    }

    // 点击滑动除popover外任意部分需要隐藏popover
    if (!window.deSuggestedSaleComponnet) {
      window.deSuggestedSaleComponnet = {
        lock: false,
        drawerContainerDom: null,
        popoverActiveId: ref(''),
        touchEvent: window.document.addEventListener('touchstart', touchHandler)
      }
    }

    const { drawerContainerDom } = window.deSuggestedSaleComponnet
    
    // 是否在弹窗内 需要绑定弹窗的事件
    const _drawerContainerDom = iconCaution.value.closest('.S-drawer__container')
    if (_drawerContainerDom && drawerContainerDom !== _drawerContainerDom) {
      // 如果之前有事件，先移除
      if (drawerContainerDom) {
        drawerContainerDom.removeEventListener('touchstart', touchHandler)
      }

      window.deSuggestedSaleComponnet.drawerContainerDom = _drawerContainerDom
    }

    watch(
      window.deSuggestedSaleComponnet.popoverActiveId,
      (newVal) => {
        if ((!newVal || newVal !== id.value) && popoverActive.value) {
          popoverActive.value = false
        }
      }
    )
  }

  
  if (window.requestIdleCallback) {
    window.requestIdleCallback(handleFun)
  } else {
    setTimeout(handleFun, 50)
  }
})

</script>

<style lang="less" scoped>
.suggested-popover {
  display: flex;
  align-items: center;
}
.icon-suggested-caution {
  position: relative;
  top: -1px;

  margin-left: 1px;
  width: .32rem;
  height: .32rem;

  opacity: 0;
}


.sh_m_sui_icon_caution_12px_2 {
  background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwLjVDOS4wMzc1NyAwLjUgMTEuNSAyLjk2MjQzIDExLjUgNkMxMS41IDkuMDM3NTcgOS4wMzc1NyAxMS41IDYgMTEuNUMyLjk2MjQzIDExLjUgMC41IDkuMDM3NTcgMC41IDZDMC41IDIuOTYyNDMgMi45NjI0MyAwLjUgNiAwLjVaTTYgMS41QzMuNTE0NzIgMS41IDEuNSAzLjUxNDcyIDEuNSA2QzEuNSA4LjQ4NTI4IDMuNTE0NzIgMTAuNSA2IDEwLjVDOC40ODUyOCAxMC41IDEwLjUgOC40ODUyOCAxMC41IDZDMTAuNSAzLjUxNDcyIDguNDg1MjggMS41IDYgMS41Wk02LjUgOFY5SDUuNVY4SDYuNVpNNi41IDNWN0g1LjVWM0g2LjVaIiBmaWxsPSIjNzY3Njc2Ij4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
  background-size: 100%;
  width: .32rem;
  height: .32rem;
}
</style>
