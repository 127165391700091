<script lang="jsx">
function handleClickLink(store_code) {
  if (window) {
    const { langPath } = gbCommonInfo
    location.href = `${langPath}/store/home?store_code=${store_code}`
  }
}
export default {
  name: 'ProductCardStoreLink',
  functional: true,
  inject: ['config', 'item'],
  props: {
    storeInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { props, injections }) {
    const { storeInfo } = props
    return (
      <div
        v-expose={{
          id: '2-3-34',
          data: { storeCode: storeInfo?.storeCode, goodsId: injections.item?.goods_id },
        }}
        v-tap={{
          id: '2-3-35',
          data: { storeCode: storeInfo?.storeCode, goodsId: injections.item?.goods_id },
        }}
        class="product-card__store-link"
        vOn:click_stop={() => handleClickLink(storeInfo.storeCode)}
      >
        <div class="product-card__store-link_title">{storeInfo.title}</div>
        <i class="suiiconfont sui_icon_more_right_12px product-card__store-link_icon"></i>
      </div>
    )
  },
}
</script>

<style lang="less">
.product-card {
  &__store-link {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    height: 0.6933rem;
    margin-top: 0.16rem;
    justify-content: space-between;
    align-items: center;
    border-top: 0.0133rem solid #e5e5e5;
    &_title {
      font-size: 0.2667rem;
      color: #767676;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &_icon {
      padding: 0 0 0 0.1067rem;
      font-size: 0.2667rem;
      color: #959595;
    }
  }
}
</style>
