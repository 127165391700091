import { template } from '@shein/common-function'
import VisitorLabel from './VisitorLabel.vue'

const getVisitorLabel = ({ item }) => item.pretreatInfo?.visitorLabel || ''

const getVisitorText = ({ item, language }) => {
  const val = getVisitorLabel({ item })
  return template(val, language.SHEIN_KEY_PWA_26425) || `${val} recently viewed`
}

const hasVisitorLabel = ({ item }) => {
  return !!item.pretreatInfo?.visitorLabel
}

const setVisitorLabelAna = ({ el, item }) => {
  const val = getVisitorLabel({ item })
  if (val && item.pretreatInfo?.stockLeft) {
    el.setAttribute('data-visitor-label', `3453_${val}`)
  }
}

export {
  VisitorLabel,
  setVisitorLabelAna,
  getVisitorText,
  hasVisitorLabel,
}
