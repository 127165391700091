/* eslint-disable no-unused-vars */
import { template } from '@shein/common-function'

const svgSatrt = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E`
const svgEnd = `%3C/svg%3E`
const LABEL_ICON_MAP = {
  zan: color => {
    // return 'sui_icon_like_completed_28px'
    // <Icon name="sui_icon_like_completed_28px" size="28px" />
    return `${svgSatrt}%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.7251 13.73H5V6.4919C6.61862 5.45117 7.12215 4.29913 7.52311 3.38177C7.9584 2.38583 8.2728 1.6665 9.76191 1.6665C12.3492 1.6665 10.6265 5.92047 10.6265 5.92047C12.6206 5.92047 14.0966 7.77512 13.6491 9.71839L12.7251 13.73ZM2 6.49219H4V13.7303H2V6.49219Z' fill='%23${color}'/%3E${svgEnd}`
  },
  sold: color => {
    // return 'sui_icon_hot_12px'
    // <Icon name="sui_icon_hot_12px" size="12px" />
    return `${svgSatrt}%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.99933 13.9593C5.60307 13.9593 3.5538 11.821 3.72415 9.36742C3.98535 6.21607 6.9256 5.73785 6.49405 1.71436C7.24948 2.47966 9.63956 5.28578 9.63956 7.84262C10.832 6.87745 10.7748 5.79599 10.7748 4.52048C12.0339 6.30619 12.2857 7.57516 12.2857 9.58691C12.2743 12.0405 10.3956 13.9593 7.99933 13.9593Z' fill='%23${color}' /%3E${svgEnd}`
  },
  view: color => {
    // return 'sui_icon_view_10px'
    // <Icon name="sui_icon_view_10px" size="10px" />
    return `${svgSatrt}%3E%3Cmask id='p' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23p)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.99999 12.8C11.5346 12.8 14.4 9.60001 15.2 8.00001C14.4 6.40001 11.5346 3.20001 7.99999 3.20001C4.46537 3.20001 1.59999 6.40001 0.799988 8.00001C1.59999 9.60001 4.46537 12.8 7.99999 12.8ZM8 10.4C9.32548 10.4 10.4 9.32548 10.4 8C10.4 6.67452 9.32548 5.6 8 5.6C6.67452 5.6 5.6 6.67452 5.6 8C5.6 9.32548 6.67452 10.4 8 10.4Z' fill='%23${color}'/%3E%3C/g%3E${svgEnd}`
  },
  shop: color => {
    // return 'sui_icon_tab_cart_selected_30px'
    // <Icon name="sui_icon_tab_cart_selected_30px" size="30px" />
    const p = `fill='%23${color}'/%3E`
    return `${svgSatrt}%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.47596 3.80952H3.46486L4.38497 11.1013H13.4781L14.3982 3.80952H4.58627L4.34335 1.84906H1.63428V2.95095H3.36957L3.47596 3.80952Z' ${p}%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.16332 14.2331C5.58593 14.2331 5.92853 13.8905 5.92853 13.4678C5.92853 13.0452 5.58593 12.7026 5.16332 12.7026C4.74071 12.7026 4.39812 13.0452 4.39812 13.4678C4.39812 13.8905 4.74071 14.2331 5.16332 14.2331Z' ${p}%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.4765 13.4678C13.4765 13.0452 13.1339 12.7026 12.7113 12.7026C12.2887 12.7026 11.9461 13.0452 11.9461 13.4678C11.9461 13.8905 12.2887 14.2331 12.7113 14.2331C13.1339 14.2331 13.4765 13.8905 13.4765 13.4678Z' ${p}${svgEnd}`
  },
  cart: color => {
    // return 'sui_icon_shoppingbag_16px_2'
    // <Icon name="sui_icon_shoppingbag_16px_2" size="16px" />
    return `${svgSatrt}%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.00017 1.06613C9.80839 1.06613 11.2952 2.44241 11.4701 4.20459H13.9284V14.6661H2.07196V4.20459H4.5302C4.70516 2.44241 6.19194 1.06613 8.00017 1.06613ZM5.58386 4.20459H10.4165C10.2473 3.02164 9.22991 2.11229 8.00017 2.11229C6.77042 2.11229 5.75307 3.02164 5.58386 4.20459Z' fill='%23${color}'/%3E${svgEnd}`
  },
  warn: color => {
    // return 'sui_icon_reported_20px'
    // <Icon name="sui_icon_reported_20px" size="20px" />
    return `${svgSatrt}%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8ZM7.25 8.74999V4.24999H8.75V8.74999H7.25ZM7.25 11.75V10.25H8.75V11.75H7.25Z' fill='%23${color}'/%3E${svgEnd}`
  },
  fave: color => {
    // return 'sui_icon_save_12px'
    // <Icon name="sui_icon_save_12px" size="12px" />
    return `${svgSatrt}%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.99999 13.6867C7.99999 13.6867 11.6312 10.3762 12.8058 8.88087C13.9804 7.38549 14.0532 5.61135 12.8058 4.36389C11.5583 3.11678 9.53627 3.11678 8.28881 4.36389L7.99999 4.6527L7.71118 4.36389C6.46372 3.11678 4.44166 3.11678 3.1942 4.36389C1.94673 5.61135 2.01956 7.38549 3.1942 8.88087C4.36883 10.3762 7.99999 13.6867 7.99999 13.6867Z' fill='%23${color}'/%3E${svgEnd}`
  },
  lowest: color => {
    // return 'sui_icon_lowest_price_12px_2'
    // <Icon name="sui_icon_lowest_price_12px_2" size="12px" />
    const p = `stroke='%23${color}' stroke-width='1.6'/%3E`
    return `${svgSatrt}%3Cpath d='M4.33331 4.86184L7.3095 7.60477L9.66665 5.99993L14.3333 10.6667' ${p}%3Cpath d='M14.3333 7.33334V10.6667H11' ${p}%3Cpath d='M1.66669 2.66666V14H15.6667' ${p}${svgEnd}`
  },
  user: () => {
    return '//shein.ltwebstatic.com/svgicons/2024/03/28/17116123943393322579.svg'
  },
  user1: color => {
    return `${svgSatrt}%3Ccircle opacity='0.3' cx='8' cy='8' r='8' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.14955 12.4663C3.64773 10.944 5.73193 10 8.0366 10C10.3201 10 12.3871 10.9267 13.8821 12.4244C14.8099 11.1929 15.3599 9.66068 15.3599 8C15.3599 3.93518 12.0648 0.64 7.99994 0.64C3.93512 0.64 0.63994 3.93518 0.63994 8C0.63994 9.67957 1.20253 11.2277 2.14955 12.4663ZM14.3228 12.9019C12.8592 14.7869 10.5713 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.84699 15.3741 11.5478 14.3228 12.9019ZM11.2598 5.62945C11.2598 7.42949 9.80058 8.88871 8.00054 8.88871C6.20051 8.88871 4.74129 7.42949 4.74129 5.62945C4.74129 3.82941 6.20051 2.37019 8.00054 2.37019C9.80058 2.37019 11.2598 3.82941 11.2598 5.62945Z' fill='%23${color}'/%3E${svgEnd}`
  }
}
// 展示优先级：实时购买（3878）＞实时好评（3527）＞商品已复购用户数（207）＞商品近30天加购人数（3460）＞商品近30天收藏人数（3457）＞商品2年内5星好评数（3726）＞近30天累计浏览（3454），当只有1个标签时不轮播，直接展示，当所有标签均没有数据时，隐藏这一栏
// const allLabels = [
//   '3526',
//   '3527',
//   '1661',
//   '207',
//   '3460',
//   '3457',
//   '3726',
//   '3454'
// ]
const SA_LABEL_MAP = {
  '-1': 'show_lowstock', //低库存提醒
  '3526': 'show_bought', // 实时购买
  '3527': 'show_highlyrated', // 实时好评
  '1661': 'show_sold', // 商品近90天销量
  '207': 'show_buyagain', // 商品已复购用户数
  '3460': 'show_adds', // 商品近30天加购人数
  '3457': 'show_faves', // 商品近30天收藏人数
  '3726': 'show_fivestar', // 商品2年内5星好评数
  '3454': 'show_views' // 近30天累计浏览
}

function getTimeRange(time, i18ns) {
  const disTime = Number(time)
  if (disTime < 60) {
    // 1min以内
    return template(Math.floor(disTime), i18ns[0])
  } else if (disTime < 3600) {
    // 1h以内
    return template(Math.floor(disTime / 60), i18ns[1])
  } else if (disTime <= 7200) {
    // 2h以内
    return i18ns[2]
  } else {
    // 2h以上不展示
    return ''
  }
}
const ID_MAP = {
  3878: {
    saKey: 'show_bought',
    icon: LABEL_ICON_MAP.user,
    format: (text, language) => {
      const i18n = [
        language.SHEIN_KEY_PWA_30369,
        language.SHEIN_KEY_PWA_30368,
        language.SHEIN_KEY_PWA_30367
      ]
      return getTimeRange(text, i18n)
    }
  },
  3526: {
    saKey: 'show_bought',
    icon: LABEL_ICON_MAP.user,
    format: (text, language) => {
      const i18n = [
        language.SHEIN_KEY_PWA_30369,
        language.SHEIN_KEY_PWA_30368,
        language.SHEIN_KEY_PWA_30367
      ]
      return getTimeRange(text, i18n)
    }
  },
  3527: {
    saKey: 'show_highlyrated',
    icon: LABEL_ICON_MAP.user,
    format: (text, language) => {
      const i18n = [
        language.SHEIN_KEY_PWA_30366,
        language.SHEIN_KEY_PWA_30365,
        language.SHEIN_KEY_PWA_30364
      ]
      return getTimeRange(text, i18n)
    }
  },
  1661: {
    saKey: 'show_sold',
    icon: LABEL_ICON_MAP.sold,
    format: (text, language) => {
      return text ? template(text, language.SHEIN_KEY_PWA_30374) : ''
    },
    numRange: [
      [0, 50, ''],
      [50, 100, '50+'],
      [100, 1000, num => `${Math.floor(num / 100) * 100}+`],
      [1000, 10000, num => `${Math.floor(num / 100) / 10}k+`],
      [10000, Infinity, '10k+']
    ]
  },
  207: {
    saKey: 'show_buyagain',
    icon: LABEL_ICON_MAP.shop,
    format: (text, language) => {
      return text ? template(text, language.SHEIN_KEY_PWA_30375) : ''
    },
    numRange: [
      [0, 100, ''],
      [100, 500, '100+'],
      [500, 1000, '500+'],
      [1000, Infinity, '1k+']
    ]
  },
  3460: {
    saKey: 'show_adds',
    icon: LABEL_ICON_MAP.cart,
    format: (text, language) => {
      return text ? template(text, language.SHEIN_KEY_PWA_30373) : ''
    },
    numRange: [
      [0, 5000, ''],
      [5000, 10000, num => `${Math.floor(num / 1000)}k+`],
      [10000, Infinity, '10k+']
    ]
  },
  3457: {
    saKey: 'show_faves',
    icon: LABEL_ICON_MAP.fave,
    format: (text, language) => {
      return text ? template(text, language.SHEIN_KEY_PWA_30372) : ''
    },
    numRange: [
      [0, 1500, ''],
      [1500, 3000, num => `${1.5 + Math.floor((num - 1500) / 500) * 0.5}k+`],
      [3000, 10000, num => `${Math.floor(num / 1000)}k+`],
      [10000, Infinity, '10k+']
    ]
  },
  3726: {
    saKey: 'show_fivestar',
    icon: LABEL_ICON_MAP.zan,
    format: (text, language) => {
      return text ? template(text, language.SHEIN_KEY_PWA_30370) : ''
    },
    numRange: [
      [0, 100, ''],
      [100, 500, '100+'],
      [500, 1000, '500+'],
      [1000, Infinity, '1k+']
    ]
  },
  3454: {
    saKey: 'show_views',
    icon: LABEL_ICON_MAP.view,
    format: (text, language) => {
      return text ? template(text, language.SHEIN_KEY_PWA_30371) : ''
    },
    numRange: [
      [0, 28000, ''],
      [28000, 30000, '28k+'],
      [30000, 40000, '30k+'],
      [40000, 50000, '40k+'],
      [50000, Infinity, '50k+']
    ]
  }
}

const GOODS_LIMIT = 3

function getMaxLabel(item) {
  const maxObj = {}
  if (Array.isArray(item) && item.length > 0) {
    item.slice(0, GOODS_LIMIT).forEach(g => {
      const tspLabel = g.realTimeTspLabels
      if (tspLabel) {
        Object.entries(tspLabel).forEach(([k, v]) => {
          const nv = Number(v)
          maxObj[k] = maxObj[k] || nv
          if (k === '3526' || k === '3527') {
            // 评论时间与购买时间取最小值
            if (maxObj[k] > nv) {
              maxObj[k] = nv
            }
          } else {
            // 其他指标取最大值
            if (maxObj[k] < nv) {
              maxObj[k] = nv
            }
          }
        })
      }
    })
    // 榜单数据取最大值后进行数据格式化处理
    Object.keys(maxObj).forEach(key => {
      const { numRange } = ID_MAP[key] || {}
      const val = Number(maxObj[key])
      if (numRange && numRange.length > 0) {
        for (let i = 0; i < numRange.length; i++) {
          const step = numRange[i]
          if (val >= step[0] && val < step[1]) {
            maxObj[key] = typeof step[2] === 'function' ? step[2](val) : step[2]
            break
          }
        }
      }
    })
  } else {
    const tsp = item.realTimeTspLabels || {}
    Object.entries(tsp).forEach(([k, v]) => {
      maxObj[k] = v
    })
  }
  return maxObj
}

export function getEfficientLabels(item, sortLabels) {
  const arr = []
  sortLabels.forEach(key => {
    let text = ''
    let sa = SA_LABEL_MAP[key] || ''
    const tsp = item.realTimeTspLabels || {}
    if (typeof key === 'function') {
      text = (key(item) || {}).text || ''
      sa = SA_LABEL_MAP[(key(item) || {}).key] || ''
    } else {
      text = tsp[key] || ''
    }
    if (text) {
      if((key == '3526' || key == '3527')) {
        if(+text <= 7200) {
          arr.push({ text, key, sa })
        }
      } else {
        arr.push({ text, key, sa })
      }
    }
  })
  return {
    efficientLabels: arr,
    saLabels: arr.map(i => i.sa)
  }
}

export function getProductLabbels(item, language, option) {
  if (!item) return []
  const labels = getMaxLabel(item)

  const { iconColor, sortLabels, userIcon = 'user' } = option || {}
  const color = iconColor.replace(/#/g, '')
  const arr = []
  sortLabels.forEach(key => {
    let icon = ''
    let text = ''
    let sa = SA_LABEL_MAP[key] || ''
    // let saKey = ID_MAP[key].saKey
    if (typeof key === 'function') {
      const res = key(item)
      const iconFn = LABEL_ICON_MAP[res.icon]
      icon = typeof iconFn === 'function' ? iconFn(color) : (iconFn?.startsWith && iconFn.startsWith('sui_icon') ? iconFn : '')
      text = res.text
      key = res.key
      sa = SA_LABEL_MAP[res.sa]
    } else if (key === '3526' || key === '3527') {
      // 人头像处理
      const iconKey = userIcon === 'user1' ? 'user1' : 'user'
      icon = LABEL_ICON_MAP[iconKey](color)
      text = labels[key]
    } else {
      const iconFn = ID_MAP[key]?.icon
      icon = typeof iconFn === 'function' ? iconFn(color) : ''
      text = labels[key]
    }
    if (typeof ID_MAP[key]?.format === 'function') {
      text = ID_MAP[key].format(text, language)
    }
    if (icon && text) {
      // 都存在才显示到轮播图上
      arr.push({ icon, text, key, sa })
    }
  })
  return arr
}
