/**
 * 分布式 + 锦囊 + picTopNav2Query 公共交互方法
 */

import { stringifyQueryString } from '@shein/common-function'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
import { SEARCH_TYPE_ENUM, getSearchSourceBySearchType, SEARCH_SOURCE_ENUM } from 'public/src/pages/common/search_words/searchConst'

// 搜索框滚动到最右边, 把最新的搜索词露出来
function scrollSearchBox2Right() {
  const containerEl = document.querySelector('.search-input-ctn__bar-text')
  const scrollEl = document.querySelector('.search-input-ctn__search-words')
  const rightWidth = scrollEl?.scrollWidth - containerEl?.offsetWidth
  if (rightWidth) containerEl.scrollLeft = rightWidth
}


/** 期待的格式化后所拥有的属性, 参考 public/src/services/goodsItemInfo/index.js 中 dealSearchFilter 函数对于分步式筛选的定义
 * item.id = `${tag_id}_${tag_val_id}` || ''
 * item.filterId = tag_val_id || ''
 * item.filterWord = tag_val_name_lang || ''
 * item.dataSource = 1
*/
const formatSearchItemMap = {
  FLOW_CELL_SEARCH: (item = {}) => {
    const { attr_value, attr_value_id, attr_id } = item
    item.filterId = attr_value_id || ''
    item.filterWord =  attr_value || ''
    item.attr_id =  attr_id || ''
  },
  PIC_TOP_NAV2_QUERY: (item = {}) => {
    const { nav_id = '', nav_name = '' } = item || {}
    const [tag_id = '', tag_val_id = ''] = nav_id.split('_')
    item.id = nav_id
    item.filterWord = nav_name
    item.attr_id = tag_id
    item.attr_value_id = tag_val_id || nav_id
    item.filterId = tag_val_id || nav_id
  },
  SEARCH_FILTER_LABEL: (item = {}) => {
    const { tag_val_name_lang, attr_values, tag_val_id, attr_value_id } = item
    item.filterId = tag_val_id || attr_value_id || ''
    item.filterWord = tag_val_name_lang || attr_values || ''
  }
}

export function formatSearchItem(type, searchItem) {
  return formatSearchItemMap[type]?.(searchItem) || searchItem
}

export function handleSearchFilterQuery (context = {
  type: '',
  searchItem: {},
  query: {},
  searchFilterWords: []
}) {
  const { type, searchItem, query, searchFilterWords } = context
  const retQuery = { ...query }
  if (type == 'PIC_TOP_NAV2_QUERY') {
    const { goodsId, nav_id, nav_type, nav_name, rec_type, index } = searchItem
    Object.assign(retQuery, {
      nav_query_adp: goodsId,
      nav_query_id: nav_id,
      nav_query_type: nav_type,
      nav_query_pos: index + 1,
      nav_query_name: nav_name,
      nav_query_rec_type: rec_type,
      nav_to_query: 1, // 标识是点击图文导航转query
      source: 'imgNavQuery',
      sourceStatus: 1
    })
  }
  searchFilterWords.push(searchItem) // 记录选择的分布式筛选词
  const { filterWords, filterAttrIds } = searchFilterWords.reduce((acc, cur) => {
    acc.filterWords.push(cur.filterWord)
    cur.attr_id && acc.filterAttrIds.push(`${cur.filterWord},${cur.attr_id},${cur.filterId || cur.attr_value_id}`)

    return acc
  }, { filterWords: [], filterAttrIds: [] })

  if (filterWords.length) retQuery.filterWords = filterWords.join()

  if (filterAttrIds.length) retQuery.filterAttrIds = filterAttrIds.join('`')

  return retQuery
}

// 分步式搜索的点击回调, 锦囊搜索 + picTopNav2Query 参考此交互 也走这个回调
export async function handleClickSearchFilter(query, type) {
  // 删除不要的query，下面会重新生成ici
  delete query.ici

  if (this.catInfo.type == 'search') {
    const searchWord = this.catInfo?.searchWord
    const filterWordsArr = query?.filterWords?.split(',') || []
    searchWord && filterWordsArr.unshift(searchWord)
    searchWordsGlobalAnalysis.set({
      result_type: type === 'PIC_TOP_NAV2_QUERY' ? SEARCH_SOURCE_ENUM.IMG_NAV : SEARCH_TYPE_ENUM.COMPOUND_SEARCH,
      result_word: filterWordsArr.join('>')
    })

    const path = this.$route.path
    const { src_module, src_identifier, src_tab_page_id, filterWords, filterAttrIds } = query
    // 需要保留的上次搜索后的 query, 其他的筛选条件相关的 query 全部丢弃: 比如属性筛选, 排序筛选, 价格筛选等
    const reminderQuery = {
      src_module,
      src_identifier,
      src_tab_page_id,
      filterWords,
      filterAttrIds,
      search_source: type === 'PIC_TOP_NAV2_QUERY' ? SEARCH_SOURCE_ENUM.IMG_NAV : getSearchSourceBySearchType(SEARCH_TYPE_ENUM.COMPOUND_SEARCH)
    }
    if (type == 'PIC_TOP_NAV2_QUERY') {
      const { nav_query_adp, nav_query_id, nav_query_type, nav_query_pos, nav_query_name, nav_query_rec_type, nav_to_query, source, sourceStatus } = query
      Object.assign(reminderQuery, { nav_query_adp, nav_query_id, nav_query_type, nav_query_pos, nav_query_name, nav_query_rec_type, nav_to_query, source, source_status: sourceStatus })
    }
    const params = `?ici=${searchWordsGlobalAnalysis.getPageFrom(15)}&${stringifyQueryString({ queryObj: reminderQuery })}`
    this.$router.push(path + params)
    await this.$nextTick()
    scrollSearchBox2Right()
  }
}
