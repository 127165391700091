<script lang="jsx">
/**
 * @file 主图信息角标 - 左上、左下、右上、右下
 */

const labelConfig = {
  UPPER_LEFT_LABEL: {
    cls: 'product-card-upperleft',
  },
  UPPER_RIGHT_LABEL: {
    cls: 'product-card-upperright',
  },
  LOWER_LEFT_LABEL: {
    cls: 'product-card-lowerleft',
  },
  LOWER_RIGHT_LABEL: {
    cls: 'product-card-lowerright',
  },
}

const seriesLabels = ['series_image', 'series_jointly_image']
const brandLabels = ['brand_image', 'brand_jointly_image']
const ignoreLabels = ['color_information', undefined]

export default {
  name: 'ItemLocateLabels',
  inject: {
    constantData: {
      default: () => ({}),
    },
  },
  props: {
    multiColumn: {
      type: Boolean,
      default: false,
    },
    lazyImg: {
      type: String,
      default: '',
    },
    imgDesignWidth: {
      type: Number,
      default: 0,
    },
    locateLabels: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    wrapperCls() {
      return {
        'product-card-is-multi': this.multiColumn,
      }
    },
    labelItems() {
      return this.getLabelItems(this.locateLabels)
    },
  },
  methods: {
    getLabelItems(labels) {
      const { GB_cssRight } = this.constantData
      const data = labels || {}
      const result = []
      Object.keys(data).forEach(key => {
        if (!Object.values(data[key] || {}).length) {
          return
        }
        const materialValueKey = data[key].materialValueKey
        const isImgType = data[key].subscriptType === 'image' || [...seriesLabels, ...brandLabels].includes(materialValueKey)
        let icon = data[key].icon || ''

        // 忽略的标签 如: color_information 色块信息
        if (ignoreLabels.includes(materialValueKey)) {
          return
        }

        if (seriesLabels.includes(materialValueKey)) {
          icon = GB_cssRight ? data[key].corner_img_right : data[key].corner_img_left
        } else if (brandLabels.includes(materialValueKey)) {
          icon = data[key].corner_img_left
        }
        const labelText = data[key].labelLang || data[key].labelLangEn
        result.push({
          key,
          isImgType,
          cls: {
            [labelConfig[key].cls]: true,
            ['product-locatelabels-img']: isImgType,
            lazyload: isImgType,
            ['product-locatelabels-text']: !isImgType,
          },
          icon,
          labelText,
          backgroundColor: data[key].backgroundColor,
          fontColor: data[key].fontColor,
          // 是否可以显示兜底插槽的内容
          showBackup: isImgType ? !icon : !labelText
        })
      })
      return result
    },
    getTextStyle(item) {
      return {
        backgroundColor: item.backgroundColor,
        color: item.fontColor,
      }
    },
  },
  render() {
    return <div class={this.wrapperCls}>
      {
        this.labelItems.map(item => (
          item.showBackup 
            ? this.$scopedSlots?.[item.key]?.(item)
            : item.isImgType ?
              <img
                key={item.key + 'img'}
                src={this.lazyImg}
                data-src={item.icon}
                data-design-width={this.imgDesignWidth}
                class={item.cls}
                tabindex="0"
                role="img"
              /> : <div
                key={item.key + 'text'}
                class={item.cls}
                style={this.getTextStyle(item)}
              >
                {item.labelText}
              </div>
        ))
      }
    </div>
  },
}
</script>

<style lang="less" scoped>
.product-card-upperleft {
  position: absolute;
  top: 4px;
  left: 0
    /* rtl: ignore */
  ;
}

.product-card-upperright {
  position: absolute;
  top: 4px;
  right: 0
    /* rtl: ignore */
  ;
}

.product-card-lowerleft {
  position: absolute;
  bottom: 4px;
  left: 0
    /* rtl: ignore */
  ;
}

.product-card-lowerright {
  position: absolute;
  bottom: 4px;
  right: 0
    /* rtl: ignore */
  ;
}

.product-locatelabels-text {
  display: inline-block;
  line-height: 1.35;
  font-size: 10px;
  text-align: center;
  padding: 2px 5px;
  background-color: #333;
  color: #fff;
  min-width: 0.89rem;
}

/* stylelint-disable-next-line selector-class-pattern */
.product-card-is-multi {
  .product-locatelabels-text {
    font-size: 9px;
    padding: 1px 2px;
  }
}

.product-locatelabels-img {
  width: 100%;
  height: auto;

  &.product-card-lowerright {
    bottom: 0px;
  }

  &.product-card-lowerleft {
    bottom: 0px;
  }

  &.product-card-upperright {
    top: 0px;
  }

  &.product-card-upperleft {
    top: 0px;
  }
}
</style>
