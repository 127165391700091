<template>
  <div
    class="delivery-words__wrap"
  >
    <div
      class="product-card__delivery-words"
      :style="{
        '--delivery-words-display': fullWidth ? 'flex' : 'inline-flex',
        '--delivery-words-margin-top': atBottom ? '4px' : '0px',
        '--delivery-words-margin-bottom': atBottom ? '0px' : '4px'
      }"
    >
      <img 
        v-if="iconLink" 
        class="delivery-icon-img"
        :src="iconLink" 
      />
      <div 
        v-else 
        class="delivery-icon-fallback"
      >  
        <span
          
          class="delivery-icon-text"
        >
          {{ language.SHEIN_KEY_PWA_26897 }}
        </span>
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' fill='none' viewBox='0 0 5 14'%3E%3Cpath id='Rectangle 34624868' fill='%239462FF' d='M3.424 11.03 5 0H0v14a3.46 3.46 0 0 0 3.424-2.97Z'/%3E%3C/svg%3E"
          class="trapezoid-icon"
          :class="{ 'trapezoid-icon_ar': constantData.GB_cssRight }"
        />
      </div>

      <div class="delivery-words-title">
        <template v-if="isModStoreTag">
          <SwiperSlide
            class="delivery-words-title__swiper"
            :loop="!!isSlide"
            :autoplay="3000"
            :first-autoplay="1500"
            :delay-loop-init-time="1500"
            :transition-duration="300"
            vertical
            :item-length="isSlide ? 2 : 1"
            :prevent-default="false"
          >
            <SwiperSlideItem>
              <sui_icon_store_14px
                v-if="storeInfo.isNewStyleTag"
                size="12"
                color="#9462FF"
                :is-rotate="constantData.GB_cssRight"
                :style="{ marginRight: '2px' }"
              />
              <span class="delivery-words-title__text">{{
                storeInfo.title
              }}</span>
            </SwiperSlideItem>
            <SwiperSlideItem v-if="isSlide">
              <template v-if="storeInfo.isFollow">
                {{
                  language.SHEIN_KEY_PWA_24072 || 'following'
                }}
              </template>
              <div
                v-else-if="storeInfo.sales"
                class="delivery-words-title__text-sale"
              >
                <sui_icon_hot_12px
                  size="14"
                  color="#9462FF"
                  :is-rotate="constantData.GB_cssRight"
                  :style="{ marginRight: '1px' }"
                />
                <span class="delivery-words-title__text">
                  {{
                    storeSales
                  }}
                </span>
              </div>
            </SwiperSlideItem>
          </SwiperSlide>
        </template>
        <span
          v-else
          class="delivery-words-title__text"
        >
          #{{ deliveryInfo.display_title }}
        </span>
      </div>
      <sui_icon_more_right_12px_2
        size="12"
        color="#9462FF"
        :is-rotate="constantData.GB_cssRight"
        class="delivery-words__more-icon"
      />
    </div>
    <!-- 点击热区 -->
    <div
      class="delivery-hot-zone"
      @click.stop="handleClickDeliveryWords"
    ></div>
  </div>
</template>

<script>
import SwiperSlide from 'public/src/pages/components/swiperSlide/SwiperSlide.vue'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue'
import { sui_icon_more_right_12px_2, sui_icon_hot_12px, sui_icon_store_14px } from '@shein-aidc/icon-vue2'
import ICON_MAP from './config'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { langPath } = gbCommonInfo

export default {
  name: 'DeliveryWords',
  inject: {
    constantData: {
      default: () => ({})
    },
    // getPageQuery: {
    //   from: 'getPageQuery',
    //   default: () => undefined
    // },
    analysisData: {
      default: {
        entryFrom: ''
      }
    },
    config: {
      from: 'config',
      default: () => ({})
    }
  },
  components: {
    sui_icon_more_right_12px_2,
    sui_icon_hot_12px,
    sui_icon_store_14px,
    SwiperSlide,
    SwiperSlideItem
  },
  props: {
    extendToChannel: {
      type: Object,
      default: () => ({})
    },
    goodsId: {
      type: [String, Number],
      default: ''
    },
    catId: {
      type: [String, Number],
      default: ''
    },
    customCateIds: {
      type: [String, Number],
      default: ''
    },
    deliveryInfo: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    atBottom: {
      type: Boolean,
      default: false
    },
    storeInfo: {
      type: Object,
      default: () => {}
    },
    isModStoreTag: {
      type: Boolean,
      default: false
    },
    index: {
      type: [String, Number],
      default: 0
    }
  },

  computed: {
    iconLink() {
      // 兜底 
      const backupKey = typeof window !== 'undefined' ? gbCommonInfo?.appLanguage : ''
      return ICON_MAP[this.constantData.appLanguage ?? backupKey] || ''
    },
    storeSales() {
      return (this.language?.SHEIN_KEY_PWA_30374 ?? '{0} sold').replace('{0}', this.storeInfo?.sales || 0)
    },
    isSlide() {
      return this.storeInfo?.isFollow || this.storeInfo?.sales
    }
  },
  methods: {
    handleClickDeliveryWords() {
      const goods_id = this.goodsId
      const { storeCode } = this.storeInfo || {}
      const {
        trend_word_id = '',
        product_select_id = '',
        product_select_url_id = ''
      } = this.deliveryInfo

      // 禁止跳转
      if (this.config.disableApartFromTheDetailsJump) return false

      // 时尚店铺
      // store/home?store_code=3331888902&tab=items&tabId=allItem&adp=32164856
      if (this.isModStoreTag) {
        const query = {
          adp: goods_id,
          tabId: 'allItem',
          store_code: storeCode,
          isStoreTabSticky: 1,
          src_tab_page_id: getSaPageInfo.tab_page_id,
          src_identifier: `store=${storeCode}`,
          src_module: 'top_trend',
        }
        this.$router.push({
          path: `${langPath}/store/home`,
          query
        })
      } else {
        // 趋势频道落地页
        // trend-landing?trendId=219&selectId=311116&selectUrlId=158311116
        const cateInfo = this.customCateIds ? { cccCatId: this.customCateIds } : { catId: this.catId }
        const query = {
          trendId: trend_word_id,
          selectId: product_select_id,
          selectUrlId: product_select_url_id,
          adp: goods_id,
          src_tab_page_id: getSaPageInfo.tab_page_id,
          src_identifier: `trend=${trend_word_id}`,
          src_module: 'top_trend',
          entry_from: this.deliveryWordsEntryFrom(),
          ...cateInfo,
        }
        this.$router.push({
          path: `${langPath}/trend-landing`,
          query
        })
      }

      // 埋点用
      // const joinId = [product_select_id, product_select_url_id].filter(Boolean).join('_')
      const extraData = {
        goodsId: goods_id,
        product_select_id,
        product_select_url_id,
        trend_word_id,
        trend_shop_code: '-',
        pageNumber: this.getItemPageNumber(this.index),
        resultOrder: this.getItemResultOrder(this.index),
        ...this.extendToChannel
      }
      if (this.isModStoreTag) {
        extraData.trend_word_id = '-'
        extraData.trend_shop_code = storeCode
      }
      daEventCenter.triggerNotice({
        daId: '2-3-49',
        extraData
      })
      // TODO 这里直接埋点就好了 不用传出去
      // this.$parent.$emit('clickDeliveryWords', wingData)
    },
    getItemPageNumber() {
      return '-'
      // const { limit, pageNum } = typeof this.getPageQuery === 'function' ? this.getPageQuery() || {} : {}
      // if (typeof limit === 'undefined' && typeof pageNum === 'undefined') {
      //   return '-'
      // }
      // return typeof pageNum !== 'undefined' ? pageNum : parseInt(((index + 1) / limit)) + 1
    },
    getItemResultOrder(index) {
      return parseInt(index + 1)
    },
    deliveryWordsEntryFrom() { 
      if (this.analysisData?.entryFrom) {
        return this.analysisData.entryFrom
      }
      return `${window.SaPageInfo?.page_name || ''}\`-\`-\`trend_tag`
    },
  }
}
</script>

<style lang="less">
.delivery-words__wrap {
  position: relative;
  width: 100%;
  height: 100%;
  .delivery-hot-zone {
    position: absolute;
    height: .7467rem;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateZ(2px);
    z-index: 2;
  }
}
.product-card__delivery-words {
  display: var(--delivery-words-display, inline-flex);
  align-items: center;
  max-width: 100%;
  width: fit-content;
  margin-top: var(--delivery-words-margin-top, 4px);
  margin-bottom: var(--delivery-words-margin-bottom, 4px);
  // margin: auto 14px;
  // border: 1px solid red;
  color: #9462ff;
  background-color: #f4efff;
  border-radius: 2px;
  // 隐藏兜底背景色溢出
  overflow: hidden;
  font-size: 0.2933rem;
  height: .3733rem;

  .delivery-words-title {
    padding: 1px 0 1px 3px;
    overflow: hidden;
    line-height: .3733rem;
    display: inline-flex;
    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      &-sale {
        transform: translateX(-2px);
        display: flex;
      }
    }
    &__swiper {
      height: 14px;
      .s-swiper-item {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
  .delivery-words__more-icon {
    margin-left: auto;
    padding-right: 2px;
  }
  .delivery-icon-fallback {
    position: relative;
    display: flex;
    align-items: center;
    height: .3733rem;
    line-height: .3733rem;
    font-size: .2933rem;
    font-weight: 600;
    color: #fff;
    background-color: #9462ff;
    margin-right: .1333rem;
    
    .delivery-icon-text {
      padding: 1px 0 1px 3px;
      height: .3733rem;
      margin-bottom: 1px;
    }
  }
  .delivery-icon-img {
    height: 100%;
    flex-basis: 0;
  }
  .trapezoid-icon {
    height: .3733rem;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(90%);
    font-size: 0;
    width: fit-content;
    &_ar {
      transform: translateX(90%) rotateY(180deg);
    }
  }
}
</style>
