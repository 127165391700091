
<script lang="jsx">
// comp
import SwiperSlide from 'public/src/pages/components/swiperSlide/SwiperSlide.vue'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue'
import ProductCardSellingPropositionRank from './ProductCardSellingPropositionRank.vue'
import ProductCardAutoWrap from './ProductCardAutoWrap.vue'
import { exposeHorseRaceLabel } from '../../analysis/index.js'

const textDataLabel = ({ text, fontStyle, style = {}, icon = '', labelLangEn = '', index, tagId, suiIcon }) => {
  return {
    type: 'text',
    random: Math.random(),
    style: style,
    textData: {
      text,
      fontStyle,
      icon,
      tagId,
      labelLangEn,
      suiIcon,
    },
    labelIndex: index
  }
}

// 被轮播标签的高度
const SLIDE_HEIGHT = 0.373
// 轮播的行数
const SLIDE_ROW = 2
// 多少个标签为一行
const ONE_LINE_LABEL_NUM = 2
// 最多显示标签数量
const MAX_LABEL_NUM = 4

// 商品项赛马标签容器
// 展示逻辑： 有榜单的时候展示榜单，没有则所有标签以轮播形式展示
export default {
  name: 'ProductCardHorseRaceLabelContainer',
  components: { 
    SwiperSlide,
    SwiperSlideItem,
    ProductCardSellingPropositionRank, 
    ProductCardAutoWrap
  },
  data() {
    return {
      // 展示模式，
      // 单行榜单rank 单行标签（只有一个）singleRow 多行标签（多个轮播）swiper
      displayMode: '',
      rankingList: null,
      isMounted: false,
      swiperLabelList: [],
    }
  },
  inject: {
    labelsFromKey: {
      default: ''
    },
    setAttrForAnalysis: {
      default: (() => {})
    },
    config: {
      default: () => ({})
    },
    item: {
      default: () => ({})
    },
    language: {
      default: () => ({})
    },
    constantData: {
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
  },
  created() {
    this.initComponentsData()
  },
  mounted() {
    setTimeout(() => {
      this.switchDisplayMode()
    }, 500)
  },
  methods: {
    switchDisplayMode() {
      const getHorseRaceLabels = this.$refs.autoWrapRef?.querySelectorAll('.product-card__auto-wrap > .product-card__auto-label') || []

      if (getHorseRaceLabels.length === 0) {
        return
      }

      const renderFirstSlide = []
      const renderSecondSlide = []

      Array.from(getHorseRaceLabels).forEach((label, index) => {
        const labelOffsetTop = this.getRelativeOffsetTop(label, this.$refs.autoWrapRef)
        const labelHeight = this.getLabelHeight(label)

        const swiperLabelListFilted = this.swiperLabelList.map(item => item.type === 'slot' || item.type === 'gap' ? undefined : item).filter(Boolean)

        if (labelOffsetTop === 0) {
          swiperLabelListFilted[index] && renderFirstSlide.push(swiperLabelListFilted[index])
        }
        
        if (labelOffsetTop === labelHeight) {
          swiperLabelListFilted[index] && renderSecondSlide.push(swiperLabelListFilted[index])
        }
      })

      // 添加换行2个标签换行逻辑
      const firstSlideItems = []
      renderFirstSlide.forEach((item, index) => {
        if (index === 1 || index === 3) {
          firstSlideItems.push(item)
          firstSlideItems.push({ type: 'br' })
        } else {
          firstSlideItems.push(item)
        }
      })

      const secondSlideItems = []
      renderSecondSlide.forEach((item, index) => {
        if (index === 1 || index === 3) {
          secondSlideItems.push(item)
          secondSlideItems.push({ type: 'br' })
        } else {
          secondSlideItems.push(item)
        }
      })

      // 添加2个标签同一行 '|' ui 展示逻辑
      const allSlideItems = []

      firstSlideItems.forEach((item, index) => {
        if (item.type !== 'br' && firstSlideItems[index + ONE_LINE_LABEL_NUM]?.type === 'br') {
          allSlideItems.push(item)
          allSlideItems.push({ type: 'line' })
        } else {
          allSlideItems.push(item)
        }
      })

      secondSlideItems.forEach((item, index) => {
        if (item.type !== 'br' && secondSlideItems[index + ONE_LINE_LABEL_NUM]?.type === 'br') {
          allSlideItems.push(item)
          allSlideItems.push({ type: 'line' })
        } else {
          allSlideItems.push(item)
        }
      })

      const swiperLabelListFilted = this.swiperLabelList.map(item => item.type === 'slot' || item.type === 'gap' ? undefined : item).filter(Boolean)

      if (swiperLabelListFilted.length > ONE_LINE_LABEL_NUM || renderSecondSlide.length > 0) {
        if (this.rankingList && this.config.showRankingList && this.config.addRankingLabelToSwiper) {
          allSlideItems.unshift({ type: 'slot', random: Math.random() })
        }
        this.displayMode = 'swiper'
      }

      this.swiperLabelList = allSlideItems

      this.isMounted = true
    },
    initComponentsData() {
      const { showRankingList, addRankingLabelToSwiper } = this.config ?? {}
      const pretreatInfo = this.item?.pretreatInfo ?? {}

      const labelsFromKey = this.labelsFromKey
      const { rankingList } = pretreatInfo?.[labelsFromKey] ?? {}

      if (rankingList && showRankingList && addRankingLabelToSwiper) {
        this.rankingList = rankingList

        const labelLoopingData = this.handelLabelData()
        // 榜单标签优先级最高 单独展示一行
        labelLoopingData.swiperLabelList.unshift({
          type: 'slot',
          random: Math.random(),
        })

        this.swiperLabelList = labelLoopingData.swiperLabelList.slice(0, MAX_LABEL_NUM)

        this.displayMode = 'singleRow'

        this.setAttrForAnalysis((el) => {
          el.setAttribute('data-ranking-list', rankingList.ana?.replace(/\s+/g, '_'))
        })

        return
      }

      if (rankingList && showRankingList) {
        this.displayMode = 'rank'
        this.rankingList = rankingList

        this.setAttrForAnalysis((el) => {
          el.setAttribute('data-ranking-list', rankingList.ana?.replace(/\s+/g, '_'))
        })
        return
      }


      const labelLoopingData = this.handelLabelData()

      if (labelLoopingData.swiperLabelList.length === 0) {
        return
      }

      // 初始化渲染时添加一条特殊的线: 针对第2个标签
      this.swiperLabelList = [labelLoopingData.swiperLabelList[0], { type: 'gap' }, ...labelLoopingData.swiperLabelList.slice(1)]
      this.displayMode = 'singleRow'
    },
    /**
     * 处理赛马标签
     */
    handelLabelData() {
      const { pretreatInfo } = this.item ?? {}
      const { showQuickShip } = this.config ?? {}
      // 来自赛马的标签数据
      const horseRaceLabel = pretreatInfo?.horseRaceLabel ?? []

      if (!horseRaceLabel.length) {
        return {
          swiperLabelList: [],
        }
      }

      const swiperLabelList = horseRaceLabel.slice(0, MAX_LABEL_NUM).map(({ 
        starComment, 
        userBehaviorLabel, 
      }, index) => {

        // 星级评论处理
        if (starComment) {
          return {
            type: 'star',
            random: Math.random(),
            starData: starComment,
            labelIndex: index,
          }
        }

        // 用户行为标签
        const { labelLang, backgroundColor, fontColor, icon, labelLangEn, tagId, suiIcon, labelCustomType } = userBehaviorLabel
        const isQuickShip = labelCustomType == 'quickship'
        const showLabel = isQuickShip ? showQuickShip : true // 是quickship标签时，需要由showQuickShip控制是否展示
        if (showLabel && userBehaviorLabel?.labelLang) {
          return textDataLabel(
            {
              index,
              text: labelLang,
              fontStyle: 'golden',
              style: {
                backgroundColor: backgroundColor,
                color: fontColor
              },
              icon,
              labelLangEn,
              tagId,
              suiIcon,
            }
          )
        }
      }).filter(Boolean)

      return {
        swiperLabelList,
      }
    },
    getRelativeOffsetTop(element, parentElement) {
      if (!element || !parentElement) return
      const rect = element.getBoundingClientRect()
      const parentRect = parentElement.getBoundingClientRect()
      // 向下取整
      return ~~(rect.top - parentRect.top)
    },
    getLabelHeight(label) {
      // 向下取整
      return ~~(label?.getBoundingClientRect().height)
    }
  },
  render() {
    // 榜单展示
    const rankingListUI = () => {
      return this.displayMode === 'rank' ? 
        <ProductCardSellingPropositionRank
          router={this.$router} 
          rankingList={this.rankingList}
          item={this.item}
          index={this.index}
          constantData={this.constantData}
          rankingLabelClickable={this.config.rankingLabelClickable && !this.constantData?.IS_RW}
        /> : null
    }

    // 单行展示
    const singleRowUI = () => {
      return (
        <SwiperSlideItem
          ref={'slideItemRef'}
          refInFor={true}
          stopPropagation
        >
          <ProductCardAutoWrap ref="autoWrapRef" isMounted={this.isMounted} rows={this.swiperLabelList} highLightRow={0} rowHeight={SLIDE_HEIGHT}>
            {/* 榜单标签加入轮播 只有 item.type === 'slot' 才会被渲染 */}
            <ProductCardSellingPropositionRank
              style={{ '--selling-rank-height': '.3733rem', '--selling-rank-margin-top': '0' }}
              router={this.$router} 
              rankingList={this.rankingList}
              item={this.item}
              index={this.index}
              constantData={this.constantData}
              rankingLabelClickable={this.config.rankingLabelClickable && !this.constantData?.IS_RW}
            />
          </ProductCardAutoWrap>
        </SwiperSlideItem>
      )

      // return this.displayMode === 'singleRow' ? (
      //   <ProductCardAutoWrap ref="autoWrapRef" isMounted={this.isMounted} rows={this.swiperLabelList} highLightRow={0} rowHeight={SLIDE_HEIGHT}>
      //     {/* 榜单标签加入轮播 只有 item.type === 'slot' 才会被渲染 */}
      //     <ProductCardSellingPropositionRank
      //       style={{ '--selling-rank-height': '.3733rem', '--selling-rank-margin-top': '0' }}
      //       router={this.$router} 
      //       rankingList={this.rankingList}
      //       item={this.item}
      //       index={this.index}
      //       constantData={this.constantData}
      //       rankingLabelClickable={this.config.rankingLabelClickable && !this.constantData?.IS_RW}
      //     />
      //   </ProductCardAutoWrap>
      // ) : null
    }

    // 多行swiper展示
    const swiperItemUI = () => {
      return Array(SLIDE_ROW).fill(null).map((_, index) => {
        return (
          <SwiperSlideItem
            ref={'slideItemRef'}
            refInFor={true}
            index={index}
            stopPropagation
          >
            <ProductCardAutoWrap ref="autoWrapRef" isMounted={this.isMounted} rows={this.swiperLabelList} highLightRow={index} rowHeight={SLIDE_HEIGHT}>
              {/* 榜单标签加入轮播 只有 item.type === 'slot' 才会被渲染 */}
              <ProductCardSellingPropositionRank
                style={{ '--selling-rank-height': '.373rem', '--selling-rank-margin-top': '0' }}
                router={this.$router} 
                rankingList={this.rankingList}
                item={this.item}
                index={this.index}
                constantData={this.constantData}
                rankingLabelClickable={this.config.rankingLabelClickable && !this.constantData?.IS_RW}
              />
            </ProductCardAutoWrap>
          </SwiperSlideItem>
        )
      })
    }

    // 多行swiper展示
    const swiperUI = () => {

      if (this.swiperLabelList.length === 0) return null

      return (
        <SwiperSlide
          ref="swiperSlideRef"
          class="product-card__horse-race-swiper"
          autoplay={3000}
          first-autoplay={1500}
          delayLoopInitTime= {1500}
          transitionDuration={300}
          itemLength={SLIDE_ROW}
          touchable={false}
          preventDefault={false}
          vOn:loopPlayStart={(slideIndex, loopIndex) => {
            if (!this.config.showHorseRaceLabel) return
            // 首轮轮播上报埋点

            if (loopIndex === 0) {
              const slideItemRef = this.$refs.slideItemRef?.[slideIndex]

              if (slideItemRef) {
                setTimeout(() => {
                  const slideItemRefEl = slideItemRef.$el
                  const sellingPointsLabels = slideItemRefEl?.querySelectorAll('.product-card__auto-wrap > .product-card__auto-label') || []
                  const labelWrap = slideItemRefEl?.querySelector('.product-card__auto-wrap')

                  let exposeHorseRaceLabelData = {
                    targets: [],
                    goods_id: this.item?.goods_id
                  } 

                  Array.from(sellingPointsLabels).forEach((label) => {
                    if (!label) return

                    const offsetTop = this.getRelativeOffsetTop(label, labelWrap)
                    const labelHeight = this.getLabelHeight(label) * slideIndex
                    if (offsetTop === labelHeight && label.getAttribute('data-is-expose') !== 'true') {
                      label.setAttribute('data-is-expose', 'true')
                      exposeHorseRaceLabelData.targets.push(label)
                    }
                  })
                  exposeHorseRaceLabelData.targets.length > 0 && exposeHorseRaceLabel(exposeHorseRaceLabelData)
                }, 1000)
              }
            }
          }}
          intersectionObserver
          loop
          vertical
        >
          { this.displayMode === 'singleRow' ? singleRowUI() : swiperItemUI() }
        </SwiperSlide>
      )
    }

    return this.displayMode ? (
      <div
        ref="propositionContainer"
        class="product-card__horse-race-container"
      >
        {/* 榜单 优先展示 */}
        { rankingListUI() }

        {/* 没有榜单 displayMode: singleRow */}
        {/* { singleRowUI() } */}

        {/* 没有榜单，displayMode: swiper */}
        { swiperUI() }
      </div>
    ) : ''
  }
}
</script>

<style lang="less">
.product-card__horse-race-swiper {
  --swiper-container-height: 0.373rem;
  height: var(--swiper-container-height, 0.373rem;);
}
.product-card__horse-race-container {
  margin: 1px 0;
  overflow: hidden; // 隐藏每个标签默认都加上的 '|' 线
}
</style>

