<script lang="jsx">
import { labelTypeMap } from '../../js/constant.js'
import { isDeSuggestedSalePrice } from '../../js/priceDisplayTheResults'

// comp
// import ProductCardSheinClubPrice from './ProductCardSheinClubPrice.vue'
import ProductCardPriceVipPrice from './ProductCardPriceVipPrice.vue'
import ProductCardNormalPrice from './ProductCardNormalPrice.vue'

import DiscountLabel from '../UI/DiscountLabel.vue'
import PriceDiscountIntro from './PriceDiscountIntro.vue'

export default {
  name: 'ProductCardPrices',
  functional: true,
  inject: ['labelsFromKey', 'config', 'language', 'constantData', 'setAttrForAnalysis', 'item'],
  props: {
    salePrice: {
      type: Object,
      default: () => ( {} )
    },
    retailPrice: {
      type: Object,
      default: () => ( {} )
    },
    // 会员专属折扣价
    exclusivePromotionPrice: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    hasFeedback: {
      type: Boolean,
      default: false
    },
    showOperatinPanel: {
      type: Boolean,
      default: false
    },
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    showBestDeal: {
      type: Boolean,
      default: false
    },
    // 显示驼峰价格样式
    showCamelPrice: {
      type: Boolean,
      default: false,
    },
    // 折扣信息
    discountInfo: {
      type: Object,
      default: () => ({})
    },
    // 到手价的一些信息
    estimatedInfo: {
      type: Object,
      default: () => ({})
    },
    // ccc返回的最终腰带
    beltLabel: {
      type: [Object, Boolean],
      default: () => ({})
    },
    // 调试开启，最终显示的价格中文
    supportVueDebug: {
      type: Boolean,
      default: false
    },
    // 付费会员的一些数据信息
    sheinClubInfo: {
      type: Object,
      default: () => ({})
    },
    // 付费会员占用售价坑位的价格
    sheinClubPriceWithSymbolVal: {
      type: String,
      default: ''
    },
    // 合规价处理数据
    specialData: {
      type: Object,
      default: () => ({})
    }
  },
  render(h, { props, data, injections, slots }) {
    const { labelsFromKey, config, language, constantData, setAttrForAnalysis, item } = injections
    
    const { pretreatInfo, salePrice, retailPrice, multiColumn, showBestDeal, exclusivePromotionPrice, showCamelPrice, discountInfo, estimatedInfo, beltLabel, sheinClubInfo, sheinClubPriceWithSymbolVal, supportVueDebug, specialData } = props || {}

    const { 
      suggestedSalePriceConfigInfo: { 
        config: { hideTargetBySuggestedSalePrice, hideDiscount } = {},
      } = {}
    } = pretreatInfo || {}
    
    const { GB_cssRight, IS_RW } = constantData || {}

    const { showSusaPrice } = specialData || {}

    // 降价金额 闪购 TODO: 后续接
    // const discountAmount = item?.discountAmount || {}

    // 付费会员
    // const sheinClubRetailPrice = (() => {
    //   const { amount: sheinClubPriceAmount } = sheinClubPrice || {}
    //   const { amount: retailPriceAmount, amountWithSymbol: retailAmountWithSymbol } = retailPrice || {}
    //   return Number(sheinClubPriceAmount) < Number(retailPriceAmount) ? retailAmountWithSymbol : ''
    // })()
    // const showSheinClubDisount = config.showSheinClubDiscountValue && sheinClubDiscountValue
    const showSheinClubNewLabel = config.showSheinClubNewLabel && sheinClubInfo?.sheinClubPriceWithSymbol

    // 新型闪购
    const newFlashPromotion = pretreatInfo?.newFlashPromotion || null
    const newFlashNewLabelText = config.showNewFlashNewLabel && newFlashPromotion
      ? newFlashPromotion?.flash_type === 2
        ? `Est. ${language?.SHEIN_KEY_PWA_26353 || 'New user price'}`
        : newFlashPromotion?.flash_type === 3
          ? `Est. ${language?.SHEIN_KEY_PWA_26352 || 'Exclusive'}`
          : ''
      : ''
    const flashTypeText = !newFlashNewLabelText && newFlashPromotion?.flashTypeText || ''
    // const showNewFlashLabelBelowPrice = config.showNewFlashLabelBelowPrice && flashTypeText
    const isABPrice = item?.promotionInfo?.length && item?.promotionInfo?.some(item => +item?.typeId === 32)

    // 到手价的一些信息
    const { 
      estimatedPriceWithSymbolVal, 
      estimatedPriceWithSymbol, 
      reachTreshold 
    }  = estimatedInfo || {}
    const { 
      estimatedTextLabel, 
      isSheinClubEstimatedPrice
    } = (() => {
      const { estimatedPriceType } = item.estimatedPriceInfo || {}
      const isSheinClubEstimatedPrice = estimatedPriceType === 2 // 是付费会员到手价
      // 新客到手价[pageId=1340027622]
      const isNewUserEstimatedPrice = [23, 56, 57, 65].includes(item.estimatedPriceInfo?.estimatedCouponTypeId) 
      const isNewCouponProduct = item?.isNewCoupon
      const isShowNewuseronlyPriceLabel = config.showNewuseronlyPriceLabel && isNewUserEstimatedPrice && isNewCouponProduct && estimatedPriceWithSymbolVal // 当前为新客到手价 & 根据ab展示新客到手价说明
  
      // 到手价标签新样式（仅满足门槛时，Estimated文字标签）
      const reachTresholdEstimatedPrice = reachTreshold && (config.showEstimatedPriceOnSale) && estimatedPriceWithSymbol
      const showEstimatedTextLabel = (isShowNewuseronlyPriceLabel || reachTresholdEstimatedPrice || isABPrice) && 
      !config.noNeedAnyEstimatedPriceTag
      // const showEstimatedTextLabel = !showSheinClubDisount && reachTreshold && config.showEstimatedPriceOnSale && estimatedPriceWithSymbol
      const estimatedTextLabel = showEstimatedTextLabel
        ? (isShowNewuseronlyPriceLabel && `Est.${language.SHEIN_KEY_PWA_26353}`) || language.SHEIN_KEY_PWA_26364 || 'Estimated'
        : ''
      return {
        estimatedTextLabel,
        isSheinClubEstimatedPrice
      }
    })()
    // 价格右侧的标签。 优先级：小号付费会员折扣标签 > 普通折扣标签 > 销量标签
    // warn: 标签有个宽度计算过程，当宽度不够时隐藏, 计算逻辑在Item.vue中
    const discountEl = (() => {
      const isDeSuggestedSalePriceType = isDeSuggestedSalePrice(discountInfo?.suggestedSalePriceType)
      const showDiscountLabel = !isDeSuggestedSalePriceType && config.showDiscountLabelAfterPrice && discountInfo?.show && !hideDiscount

      const { salesLabels = [] } = item?.[labelsFromKey] ?? {}
      const hasSalesLabel = !!Object.keys(salesLabels || {})?.length
      const labelCarouselConfig = config?.showLabelCarousel || false
      const showSalesLabel = labelCarouselConfig ? false : config.hasOwnProperty('showSalesLabel') ?
        (config.showSalesLabel && hasSalesLabel) :
        hasSalesLabel

      if (showDiscountLabel) { // 普通折扣标签
        return (
          <DiscountLabel
            type='discount'
            discount-info={discountInfo}
            specialData={specialData}
          />
        )
      } else if (showSalesLabel) { // 销量标签
        // 埋点
        setAttrForAnalysis((el) => {
          el.setAttribute('data-sales-label', `show_sales_label_${labelTypeMap.get(salesLabels.textType)}_${salesLabels.labelId}_${salesLabels.labelLang?.replace(/\s+/g, '_')}`)
        })

        const styles = { color: salesLabels?.fontColor || '', backgroundColor: salesLabels?.backgroundColor || '' }
        return (
          <div 
            class="product-card__sales-label"
            style={styles}
          >
            {salesLabels.labelLang}
          </div>
        )
      } 
      return null
    })()

    // 价格下面的标签
    const belowPriceLabelEl = (() => {
      if (hideTargetBySuggestedSalePrice) return null // 不展示价格的标签

      // 付费会员标签
      const sheinClubLabelEl = (() => {
        if (showSheinClubNewLabel) {
          return <div class="product-card__text-label product-card__text-label_sheinclub">Est. SHEIN CLUB { language.SHEIN_KEY_PWA_26354 || 'price' }</div>
        } 
        return null
      })()
      // 新型闪购标签
      const newFlashLabelEl = (() => {
        if (newFlashNewLabelText) {
          return <div class="product-card__text-label product-card__text-label_discount">{newFlashNewLabelText}</div>
        }
        return null
      })()
      // 到手价标签
      const estimatedLabelEl = estimatedTextLabel ? 
        ( <div class="product-card__text-label product-card__text-label_discount">{ estimatedTextLabel }</div> ) : null

      // 价格优势说明标签
      const pdiEl = (() => {
        const { historicalPrice, followPrice } = item?.[labelsFromKey] ?? {}

        return config.showPriceDiscountDesc ? <PriceDiscountIntro
          setAttrForAnalysis={setAttrForAnalysis}
          config={config}
          historicalPrice={historicalPrice}
          followPrice={followPrice}
          salePrice={salePrice}
          retailPrice={retailPrice} /> : null
      })()

      return sheinClubLabelEl || newFlashLabelEl || estimatedLabelEl || pdiEl || slots().belowPrice
    })()

    let priceClassName = {
      'product-card__prices': true
    }
    if (showSusaPrice) {
      priceClassName = Object.assign(priceClassName, {
        'price-height-40': true,
      })
    } else {
      priceClassName = Object.assign(priceClassName, {
        'price-overflow-hidden': true,
        // 一行多图 无大小数字 15
        'price-height-15': multiColumn && !showCamelPrice,
        // 一行二图 无大小数字 21
        'price-height-21': !multiColumn && !showCamelPrice,
        // 大小数字统一为20
        'price-height-20': showCamelPrice || config?.dynamicCamelPrice,
      })
    }
    
    const PriceV2LabelPosition = config?.PriceV2LabelPosition || 'priceBottom'

    return (
      <div
        class={[
          data.class,
          data.staticClass,
        ]} 
      >
        {/* 价格上方 */}
        {
          config.hasOwnProperty('showNewFlashNewLabel') ? (config.showNewFlashNewLabel && flashTypeText) : flashTypeText
            ? <div class="normal-price__flash-label">{flashTypeText}</div> 
            : null
        }

        {/* 价格 */}
        <div 
          class={priceClassName}
        >
          {
            <div class="product-card__prices-info">
              {/* 普通价格（售卖价和零售价） */}
              {
                config.hideNormalPrices ? null : (
                  <ProductCardNormalPrice
                    new-flash-promotion={newFlashPromotion}
                    sale-price={salePrice}
                    retail-price={retailPrice}
                    pretreat-info={pretreatInfo}
                    multi-column={multiColumn}
                    show-camel-price={showCamelPrice}
                    show-best-deal={showBestDeal}
                    estimated-price-with-symbol-val={estimatedPriceWithSymbolVal}
                    is-shein-club-estimated-price={isSheinClubEstimatedPrice}
                    shein-club-info={sheinClubInfo}
                    belt-label={beltLabel}
                    special-data={specialData}
                    shein-club-price-with-symbol-val={sheinClubPriceWithSymbolVal}
                    support-vue-debug={supportVueDebug}
                  />
                )
              }
            </div>
          }
          
          {/* 到手价标签和付费会员标签 */}
          { PriceV2LabelPosition === 'priceRight' ? belowPriceLabelEl : null }

          { discountEl }

          {/* 新型闪购标签 */}
          {/* {
            showNewFlashLabelBelowPrice 
              ? (
                <div class="product-card__newflash-label">{flashTypeText}</div>
              ) : null
          } */}
          
        </div>

        {/* 价格下方 */}
        {/* vip会员专属价格 */}
        {
          exclusivePromotionPrice && exclusivePromotionPrice.amountWithSymbol && !config.hideS3VipPrice
            ? (
              <ProductCardPriceVipPrice
                is-rw={IS_RW}
                amount-with-symbol={exclusivePromotionPrice.amountWithSymbol}
                css-right={GB_cssRight}
              />
            )
            : null
        }
      
        {/* 到手价标签和付费会员标签 */}
        { PriceV2LabelPosition === 'priceBottom' ? belowPriceLabelEl : null }
      </div>
    )
  }
}
</script>

<style lang="less">
.product-card__newflash-label {
  .font-dpr(20px);
  display: inline-block;
  height: 0.4267rem;
  line-height: 0.4267rem;
  white-space: nowrap;
  padding: 0 0.1067rem;
  margin-top: 0.1067rem;
  color: @sui_color_promo_dark;
  background: @sui_color_promo_bg;
}

.product-card {
  &__prices {
    .flexbox();
    align-items: baseline;
    flex-wrap: wrap;
    text-align: left;
    
    &.price-height-15 {
      max-height: .3733rem;
    }
    &.price-height-20 {
      max-height: .5333rem;
    }
    &.price-height-21 {
      max-height: 21px;
    }
    &.price-height-40 {
      max-height: 1.06rem;
    }
    .product-card__discount {
      margin-left: .107rem;
      align-self: center;
    }

    .product-card__text-label {
      margin-left: .107rem;
    }

  }

  .price-overflow-hidden {
    overflow: hidden; // checkout button 过长的时候会隐藏 销量标签
  }

  &__prices-info {
    flex: 0 0 auto;
    max-width: 100%;

    .flexbox();
    .align-center();
    .space-between();
    position: relative;
    flex-wrap: wrap;
    
    .sui_icon_more_20px { /* stylelint-disable-line */
      position: absolute;
      top: 0;
      right: 0;
      height: 0.4533rem;
      line-height: 0.4533rem;
      color: #959595;
    }
  }
  &__sales-label {
    margin-left: 0.0533rem;
    font-size: 0.2933rem;
    color: @sui_color_gray_dark3;
    line-height: 0.3467rem;
    white-space: nowrap;
  }
  &__sheinclub-discount_small {
    margin-left: 0.1067rem;
  }
  &__newflash-label {
    margin-top: 0.0533rem;
  }
  &__text-label {
    font-size: 0.2667rem;
    line-height: 0.32rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    &_sheinclub {
      color: @sui_color_club_rosegold_dark1;  
    }
    &_discount {
      color: @sui_color_discount;
    }
  }

}
</style>
