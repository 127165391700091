
<script lang="jsx">
import { labelTypeMap } from '../../js/constant.js'

// comp
import SwiperSlide from 'public/src/pages/components/swiperSlide/SwiperSlide.vue'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue'
import ProductCardSellingPropositionRank from './ProductCardSellingPropositionRank.vue'
import ProductCardSellingPropositionSlideItem from './ProductCardSellingPropositionSlideItem.vue'

const textDataLabel = ({ text, fontStyle, style = {}, suiIcon }) => {
  return {
    type: 'text',
    random: Math.random(),
    style: style,
    textData: {
      text,
      fontStyle,
      suiIcon, 
    }
  }
}

// 商品项卖点容器
// 展示逻辑： 有榜单的时候展示榜单，没有则所有标签以轮播形式展示
export default {
  name: 'ProductCardSellingPropositionContainer',
  components: { 
    SwiperSlide,
    SwiperSlideItem,
    ProductCardSellingPropositionRank, 
    ProductCardSellingPropositionSlideItem
  },
  props: {
    // 插槽单独使用，非商卡配置项
    showStarCommentSlot: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      // 展示模式，
      // 单行榜单rank 单行标签（只有一个）singleRow 多行标签（多个轮播）swiper
      displayMode: '',
      rankingList: {},

      swiperLabelList: [],
    }
  },
  inject: {
    labelsFromKey: {
      default: ''
    },
    setAttrForAnalysis: {
      default: (() => {})
    },
    config: {
      default: () => ({})
    },
    item: {
      default: () => ({})
    },
    language: {
      default: () => ({})
    },
    constantData: {
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
  },
  created() {
    this.initComponentsData()
  },
  methods: {
    initComponentsData() {
      const { showRankingList, addRankingLabelToSwiper } = this.config ?? {}
      const pretreatInfo = this.item?.pretreatInfo ?? {}

      const labelsFromKey = this.labelsFromKey
      const { rankingList } = pretreatInfo?.[labelsFromKey] ?? {}

      if (rankingList && showRankingList && addRankingLabelToSwiper) {
        this.rankingList = rankingList

        const labelLoopingData = this.handelLabelData()
        // 榜单标签优先级最高 单独展示一行
        labelLoopingData.swiperLabelList.unshift({
          type: 'slot',
          random: Math.random(),
        })

        this.swiperLabelList = labelLoopingData.swiperLabelList.slice(0, 2)

        if (this.swiperLabelList.length === 1) {
          this.displayMode = 'singleRow'
        } else if (this.swiperLabelList.length > 1) {
          this.displayMode = 'swiper'
        }

        return
      }

      if (rankingList && showRankingList) {
        this.displayMode = 'rank'
        this.rankingList = rankingList

        this.setAttrForAnalysis((el) => {
          el.setAttribute('data-ranking-list', rankingList.ana?.replace(/\s+/g, '_'))
        })

        return
      }

      const labelLoopingData = this.handelLabelData()
      this.swiperLabelList = labelLoopingData.swiperLabelList

      if (this.swiperLabelList.length === 1) {
        this.displayMode = 'singleRow'
      } else if (this.swiperLabelList.length > 1) {
        this.displayMode = 'swiper'
      }

      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-label-looping', labelLoopingData.ana?.replace(/\s+/g, '_') || '')
        el.setAttribute('data-extra-tag-proposition', labelLoopingData.extraTagProposition?.replace(/\s+/g, '_') || '')
      })
    },
    /** 
     * 处理星级评论  &&  文本标签数据
     * 包含以下类型
     *  低库存 用户行为标签 用户评论关键词标签 优质评论 商品属性 人工卖点
     * 
     * @returns { swiperLabelList: Array } { starData: 星级评论数据, textData: 文字数据 }
     * starData { comment_rank_average: 星级, comment_num_str: 分数 }
     * textData { text: 展示的文字, fontStyle: 字体颜色 gary灰  golden金 }
    */
    handelLabelData() {
      const { pretreatInfo } = this.item ?? {}
      const { IS_RW } = this.constantData
      
      const { 
        showStarComment, 
        showDecisionAttr, 
        showSellingPoint,
        showLeftStock,
        showUserBehaviorLabel,
        showUserPreferencesLabel,
        showQuickShip
      } = this.config ?? {}

      const labelsFromKey = this.labelsFromKey
      const { sellingPointUniversalLabels = [], stockLeft } = pretreatInfo?.[labelsFromKey] ?? {}

      const swiperLabelList = []
      // 在上报时需要额外上报一次的标签
      const extraTagProposition = []
      const anaText = []

      // <!-- TODO: 低库存 剩余库存（仅romwe）0-10(不含首尾) 例: only 2 left -->
      if (IS_RW && showLeftStock && stockLeft) {
        swiperLabelList.push(
          textDataLabel({ text: stockLeft, fontStyle: 'golden' })
        )
      }

      if (!sellingPointUniversalLabels?.length) {
        return {
          swiperLabelList,
          ana: `show_label_Looping_${swiperLabelList.length}_${anaText.join('_')}`,
          extraTagProposition: extraTagProposition.join('|')
        }
      }

      sellingPointUniversalLabels?.slice(0, 2).forEach(({ 
        quickship,
        starComment, 
        userBehaviorLabel, 
        userPreferencesLabel,
        userCommentKeywordsLabel, 
        decisionAttrList,
        sellingPoints,
        materialValueKey
      }) => {
        // quickship
        if (showQuickShip && quickship) {
          const { labelLang, suiIcon, fontColor } = quickship || {}
          swiperLabelList.push(
            textDataLabel({ 
              text: labelLang,
              suiIcon,
              style: {
                color: fontColor
              }
            })
          )
          anaText.push('show_quickship')
        } else if  (starComment && (showStarComment || this.showStarCommentSlot )) {
          // 星级评论处理
          swiperLabelList.push({
            type: 'star',
            random: Math.random(),
            starData: starComment
          })

          anaText.push(`${materialValueKey}_${starComment.comment_num_str}`)
        }

        // 用户行为标签
        if (showUserBehaviorLabel && userBehaviorLabel?.labelLang) {
          swiperLabelList.push(
            textDataLabel({
              text: userBehaviorLabel.labelLang, 
              fontStyle: 'golden', 
              style: { 
                backgroundColor: userBehaviorLabel.backgroundColor, 
                color: userBehaviorLabel.fontColor
              }
            })
          )

          const _anaText = `show_behavior_label_${labelTypeMap.get(userBehaviorLabel.textType)}_${userBehaviorLabel.labelId}_${userBehaviorLabel?.labelLang}`
          
          anaText.push(_anaText)
          extraTagProposition.push(_anaText)
        }

        // 用户偏好标签
        if (showUserPreferencesLabel && userPreferencesLabel) {
          swiperLabelList.push(
            textDataLabel({
              text: userPreferencesLabel.labelLang, 
              fontStyle: 'golden', 
              style: { 
                backgroundColor: userPreferencesLabel.backgroundColor, 
                color: userPreferencesLabel.fontColor
              }
            })
          )
        }

        // 评论关键词标签
        if (userCommentKeywordsLabel?.labelLang) {
          swiperLabelList.push(
            textDataLabel({
              text: userCommentKeywordsLabel.labelLang, 
              fontStyle: 'golden' 
            })
          )
          anaText.push(`${materialValueKey}_${userCommentKeywordsLabel?.labelLang}`)
        }

        // 决策属性
        if (showDecisionAttr && decisionAttrList?.length) {
          const displayDecisionAttrList = decisionAttrList.slice(0, 3).map(v => v.name)
          const text = displayDecisionAttrList.join(' | ')
          swiperLabelList.push(
            textDataLabel({
              text, 
              fontStyle: 'gray'
            })
          )

          const displayDecisionAttrListTextEn = decisionAttrList.slice(0, 3).map(v => v['name-en']).join(' | ')
          anaText.push(`${materialValueKey}_${displayDecisionAttrListTextEn}`)
        }

        // 人工卖点
        if (showSellingPoint && sellingPoints?.length) {
          let tspIds = []
          let text = []
          sellingPoints.forEach(item => {
            if (item?.tag_val_name_lang) {
              swiperLabelList.push(
                textDataLabel({
                  text: item.tag_val_name_lang, 
                  fontStyle: 'gray'
                })
              )
              tspIds.push(`${materialValueKey}_${item.tag_val_id}`)
              text.push(item.tag_val_name_en)
            }
          })

          anaText.push(`${tspIds.join('_')}_${text.join('_')}`)
        }
      })

      return {
        swiperLabelList,
        ana: `show_label_Looping_${swiperLabelList.length}_${anaText.join('_')}`,
        extraTagProposition: extraTagProposition.join('|')
      }
    },
  },
  render() {
    // 榜单展示
    const rankingListUI = () => {
      return this.displayMode === 'rank' ? 
        <ProductCardSellingPropositionRank
          router={this.$router} 
          rankingList={this.rankingList}
          item={this.item}
          index={this.index}
          constantData={this.constantData}
          rankingLabelClickable={this.config.rankingLabelClickable && !this.constantData?.IS_RW}
        /> : ''
    }

    // 单行展示
    const singleRowUI = () => {
      return this.displayMode === 'singleRow' ? 
        <ProductCardSellingPropositionSlideItem
          itemData={this.swiperLabelList[0]}
          showIcon={true}
        >
          {/* 榜单标签加入轮播 只有 item.type === 'slot' 才会被渲染 */}
          <ProductCardSellingPropositionRank
            style={{ '--selling-rank-height': '.3733rem', '--selling-rank-margin-top': '0' }}
            router={this.$router} 
            rankingList={this.rankingList}
            item={this.item}
            index={this.index}
            constantData={this.constantData}
            rankingLabelClickable={this.config.rankingLabelClickable && !this.constantData?.IS_RW}
          />
        </ProductCardSellingPropositionSlideItem> : ''
    }

    // 多行swiper item
    const swiperItemUI = () => {
      return this.swiperLabelList.map((item, index) => {
        return (
          <SwiperSlideItem
            index={index}
            stopPropagation
          >
            <ProductCardSellingPropositionSlideItem 
              itemData={item}
              showIcon={true}
            >
              {/* 榜单标签加入轮播 只有 item.type === 'slot' 才会被渲染 */}
              <ProductCardSellingPropositionRank
                style={{ '--selling-rank-height': '.3733rem', '--selling-rank-margin-top': '0' }}
                router={this.$router} 
                rankingList={this.rankingList}
                item={this.item}
                index={this.index}
                constantData={this.constantData}
                rankingLabelClickable={this.config.rankingLabelClickable && !this.constantData?.IS_RW}
              />
            </ProductCardSellingPropositionSlideItem>
          </SwiperSlideItem>
        )
      })
    }

    // 多行swiper展示
    const swiperUI = () => {
      return this.swiperLabelList.length > 1 ? 
        <SwiperSlide
          ref="swiperSlideRef"
          class="product-card__selling-proposition-swiper"
          autoplay={3000}
          first-autoplay={1500}
          delayLoopInitTime= {1500}
          transitionDuration={300}
          itemLength={this.swiperLabelList.length}
          touchable={false}
          preventDefault={false}
          intersectionObserver
          loop
          vertical
        >
          { swiperItemUI() }
        </SwiperSlide> : ''
    }

    return this.displayMode ? (
      <div
        ref="propositionContainer"
        class="product-card__selling-proposition-container"
      >
        {/* 榜单 优先展示 */}
        { rankingListUI() }

        {/* 没有榜单，只有一个标签 */}
        { singleRowUI() }

        {/* 没有榜单，多个标签 取优先级前两个轮播展示 */}
        { swiperUI() }
      </div>
    ) : ''
  }
}
</script>

<style lang="less">
.product-card__selling-proposition-swiper {
  --swiper-container-height: 0.42rem;
  height: var(--swiper-container-height, 0.42rem);
}
.product-card__selling-proposition-container {
  margin: 1px 0;
}
</style>

