<script lang="jsx">
import { handlePriceHtml } from 'public/src/pages/components/product/item_v3/js/utils.js'
import CamelCasePrice from './CamelCasePrice.vue'
import SuggestedSalePriceContainer from './SuggestedSalePriceContainer.vue'

import { views } from 'public/src/pages/components/product/item_v3/js/constant'
import { isFrPlSuggestedSalePrice, isDeSuggestedSalePrice, isSpecialSuggestedSalePrice } from '../../js/priceDisplayTheResults'

export default {
  name: 'ProductCardNormalPrice',
  functional: true,
  components: { 
    CamelCasePrice,
    SuggestedSalePriceContainer, 
  },
  inject: ['config', 'constantData', 'item', 'labelsFromKey'],
  props: {
    newFlashPromotion: {
      type: Object,
      default: () => ( {} )
    },
    salePrice: {
      type: Object,
      default: () => ( {} )
    },
    retailPrice: {
      type: Object,
      default: () => ( {} )
    },
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    showBestDeal: {
      type: Boolean,
      default: false
    },
    // 显示驼峰价格样式
    showCamelPrice: {
      type: Boolean,
      default: false
    },
    // 到手价
    estimatedPriceWithSymbolVal: {
      type: String,
      default: ''
    },
    // 是否是付费会员到手价
    isSheinClubEstimatedPrice: {
      type: Boolean,
      default: false
    },
    // 付费会员的一些数据信息
    sheinClubInfo: {
      type: Object,
      default: () => ({})
    },
    // 付费会员占用售价坑位的价格
    sheinClubPriceWithSymbolVal: {
      type: String,
      default: ''
    },
    // ccc返回的最终腰带
    beltLabel: {
      type: [Object, Boolean],
      default: () => ({})
    },
    supportVueDebug: {
      type: Boolean,
      default: false
    },
    specialData: {
      type: Object,
      default: () => ({})
    }
  },
  render(h, { props, data, injections }) {
    const { config, constantData, item, labelsFromKey } = injections || {}

    const { newFlashPromotion, pretreatInfo, salePrice, retailPrice, showBestDeal, multiColumn, showCamelPrice, estimatedPriceWithSymbolVal, isSheinClubEstimatedPrice, sheinClubInfo, sheinClubPriceWithSymbolVal, beltLabel, supportVueDebug, specialData } = props || {}
    const { lang, currency, currencies } = constantData || {}
    const { flashZonePromotion, isInversion } = item || {}

    const suggestedSalePriceInfo = item.suggestedSalePrice

    // 是否倒挂
    const isInversionFn = (isInversion) => Number(isInversion) === 1 

    // pmsc价格倒挂
    const inversionOnPmsc = isInversionFn(isInversion)

    // 用于调试，最终价格中文显示作为
    let noteText = ''

    // 分人群专区闪购
    const { price: flashZonePrice, isInversion: isInversionOnFlashZone } = flashZonePromotion || {}
    const inversionOnFlashZone = isInversionFn(isInversionOnFlashZone) // 专区闪购价格倒挂
    const flashZonePriceWithSymbol = flashZonePrice?.amountWithSymbol || ''
    
    // 新型闪购价
    const { price: newFlashPrice, isInversion: isInversionOnNewFlash } = newFlashPromotion || {}
    const inversionOnNewFlash = isInversionFn(isInversionOnNewFlash) // 新型闪购价格倒挂
    const newFlashPriceWithSymbol = config.showNewFlashPriceOnSale && newFlashPrice?.amountWithSymbol || ''

    // 价格的一些自定义样式
    const priceStyle = (() => {
      const {
        salePriceFontSize,
        salePriceColor,
        salePriceFontWeight,
        lineThroughPriceFontSize,
        lineThroughPriceScale
      } = config.style || {}

      const style = {
        salePrice: {},
        retailPrice: {}
      }

      salePriceFontSize && (style.salePrice.fontSize = salePriceFontSize)
      salePriceColor && (style.salePrice.color = salePriceColor)
      salePriceFontWeight && (style.salePrice.fontWeight = salePriceFontWeight)
      lineThroughPriceFontSize && (style.retailPrice.fontSize = lineThroughPriceFontSize)
      lineThroughPriceScale && (style.retailPrice.transform = `scale(${lineThroughPriceScale})`)

      return style
    })()

    // 付费会员价
    const { isInversion: isInversionOnSheinClub } = sheinClubInfo || {}
    const inversionOnSheinClub = isInversionFn(isInversionOnSheinClub) // 付费会员价格倒挂

    // 售价
    const { 
      needSusaPrice,
      isInversion: isInversionVal,
      showSuggestedSalePrice, 
      salePriceEl 
    } = (() => {
      let salePriceAmountWithSymbol = null
      const needSusaPrice = !!(pretreatInfo?.suggestedSalePriceConfigInfo) // 命中合规价逻辑
      let isInversion = false // 售价:合规价是否倒挂

      let showSuggestedSalePrice = specialData.showSusaPrice // 是否展示合规价
      const classList = ['prices-info__sale-price']
      const classTypeMap = {
        promo: 'prices-info__sale-price_promo', // 促销色
        sheinClub: 'prices-info__sale-price_sheinclub' // 付费会员色
      }

      const onShowSuggestedSalePrice = ({ inversion, useSpecialColor, classType }) => {
        isInversion = !!inversion

        const suggestedSalePriceType = pretreatInfo?.suggestedSalePriceConfigInfo?.type
        const isFrPl = isFrPlSuggestedSalePrice(suggestedSalePriceType)
        const isDe = isDeSuggestedSalePrice(suggestedSalePriceType)

        // 未倒挂且有extraFactor条件成立，使用特殊色
        const extraFactor = typeof useSpecialColor !== 'undefined' ? useSpecialColor : true
        if (isFrPl) {
          if (!inversion && extraFactor) classList.push(classTypeMap[classType])
        } else if (!multiColumn && isDe && (specialData.show30DaysLowestPrice || specialData.priceInfoAndretailPriceEquivalent)) {
          // 当前售价都只有【30天最低价展示或原价等于售价】才会是促销色/付费会员色
          classList.push(classTypeMap[classType])
        } else if (!suggestedSalePriceType && extraFactor) {
          classList.push(classTypeMap[classType])
        }
      }

      if (estimatedPriceWithSymbolVal) { // 到手价-占用现售价的坑位
        if (isSheinClubEstimatedPrice) {
          noteText = '付费会员到手价 取estimatedInfo'
          classList.push(classTypeMap['sheinClub'])
        } else {
          noteText = '到手价 取estimatedInfo'
          classList.push(classTypeMap['promo'])
        }
        salePriceAmountWithSymbol = estimatedPriceWithSymbolVal
      } else if (sheinClubPriceWithSymbolVal) { // 付费会员价格-占用现售价的坑位
        noteText = '付费会员价 取sheinClubPromotionInfo'

        onShowSuggestedSalePrice({ inversion: inversionOnSheinClub, classType: 'sheinClub' })
        salePriceAmountWithSymbol = sheinClubPriceWithSymbolVal
      } else if (newFlashPriceWithSymbol) { // 新型闪购价-占用现售价的坑位
        noteText = '新型闪购价 取newFlashPromotion'

        onShowSuggestedSalePrice({ inversion: inversionOnNewFlash, classType: 'promo' })
        salePriceAmountWithSymbol = newFlashPriceWithSymbol
      } else if (flashZonePriceWithSymbol) { // 分人群专区闪购价-占用现售价的坑位
        noteText = '分人群专区闪购价 取flashZonePromotion'

        onShowSuggestedSalePrice({ inversion: inversionOnFlashZone, classType: 'promo' })
        salePriceAmountWithSymbol = flashZonePriceWithSymbol 

      } else if (salePrice.amountWithSymbol) { // 现售价
        noteText = '售价 取salePrice'

        const salePriceAmount = Number(salePrice.amount)
        const retailPriceAmount = Number(retailPrice.amount)
        const suggestedSalePriceAmount = Number(suggestedSalePriceInfo?.amount)
        const isDiscount = (suggestedSalePriceAmount || retailPriceAmount) > salePriceAmount

        // 限时直降活动，固定显示红色价格
        const showSellerLimitedLabel = pretreatInfo?.mixPromotionInfo?.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)

        const useSpecialColor = isDiscount || showBestDeal || !!showSellerLimitedLabel
        onShowSuggestedSalePrice({ inversion: inversionOnPmsc, useSpecialColor: !!useSpecialColor, classType: 'promo' })

        salePriceAmountWithSymbol = salePrice.amountWithSymbol
      }
      const camelPriceStyle = config?.style?.camelPriceStyle ?? {}
      if (salePriceAmountWithSymbol) {
        const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(salePriceAmountWithSymbol, salePrice.amount)
        return {
          needSusaPrice,
          isInversion,
          showSuggestedSalePrice,
          salePriceEl: <CamelCasePrice
            class={classList}
            show-camel-price={showCamelPrice}
            currency-symbol-prefix={currencySymbolPrefix}
            number-before-dot={numberBeforeDot}
            number-after-dot={numberAfterDot}
            currency-symbol-suffix={currencySymbolSuffix}
            origin-string={salePriceAmountWithSymbol}
            camel-price-style={camelPriceStyle}
            style={priceStyle.salePrice}
            data-note={supportVueDebug ? noteText : ''}
          />
        }
      } else {
        return {
          needSusaPrice,
          isInversion,
          showSuggestedSalePrice: false,
          salePriceEl: null
        }
      }
    })()

    // 法国波兰新划线价一行一图二图场景（合规价），德国合规价原价场景
    const suggestedSalePriceController = (() => {
      const isBroadwise = labelsFromKey === views.LIST_MOBILE_BROADWISE_VIEW

      const isDe = isDeSuggestedSalePrice(pretreatInfo?.suggestedSalePriceConfigInfo?.type)
      const isSpecialSuggested = isSpecialSuggestedSalePrice(pretreatInfo?.suggestedSalePriceConfigInfo?.type)
      const isFrPl = isFrPlSuggestedSalePrice(pretreatInfo?.suggestedSalePriceConfigInfo?.type)

      const deShowHit = isDe && !multiColumn && showSuggestedSalePrice
      const plFrShowHit = isFrPl && (showSuggestedSalePrice && suggestedSalePriceInfo)

      const showSuggestedSalePriceDom = !!deShowHit || !!plFrShowHit

      if (showSuggestedSalePriceDom) {
        const lowestPriceLabelLang = isSpecialSuggested ? pretreatInfo?.suggestedSalePriceConfigInfo?.lowestPriceLabelLang : ''
        
        return {
          priceContentCls: (isSpecialSuggested || isBroadwise) && lowestPriceLabelLang ? ['prices-info__content_column-susa'] : ['prices-info__content_row-susa'],
          priceEl: (
            <SuggestedSalePriceContainer
              goodsInfo={item}
              specialData={specialData}
              supportVueDebug={supportVueDebug}
            ></SuggestedSalePriceContainer>
          )
        }
      }
      return {
        priceContentCls: [],
        priceEl: null,
      }
    })()

    // 老划线价
    const retailPriceEl = (() => {
      const { hideTargetBySuggestedSalePrice } = pretreatInfo?.suggestedSalePriceConfigInfo?.config || {}
      const salePriceAmount = Number(salePrice.amount)
      const retailPriceAmount = Number(retailPrice.amount)
      const isDiscount = retailPriceAmount > salePriceAmount

      // 展示零售价
      const showRetailPrice = config.showRetailPrice && !hideTargetBySuggestedSalePrice && retailPrice.amountWithSymbol ? isDiscount : false

      return showRetailPrice ? (
        <p
          class={[
            'prices-info__origin-price', 
          ]}
          tabindex="0"
          role="none"
          aria-label={retailPrice.amountWithSymbol}
          data-note={supportVueDebug ? '原价 取retailPrice' : ''}
        >
          {retailPrice.amountWithSymbol}
        </p>
      ) : null
    })()

    // 闪购，限时折扣 图标
    const promotionIconListEl = (() => {
      let list = []
      if(config.hideIconBeforePrice || (needSusaPrice && (isInversionVal || multiColumn))) {
        return []
      }
      pretreatInfo.mixPromotionInfo?.forEach(i => {
        if (!i.icon) return
        // app专属图标无需展示
        if (i.typeId !== 8) {
          if (i.typeId == 10) {
            if (beltLabel?.component == 'flash_purchase_belt') return
            // 闪电标（1: 普通闪购, 4: 分人群定价专区闪购, 5: 不分人群定价专区闪购）
            if (([1, 5].includes(+i.flash_type) || +i.flash_type === 4 && flashZonePriceWithSymbol) && !config.hideFlashSaleIcon) {
              list.push(i.icon)
            }
          } else if (i.typeId === 3) {
            if (!config.hideLimitedTimeIcon) list.push(i.icon)
          } else {
            list.push(i.icon)
          }
        }
      })

      if (list?.length) {
        const showIconDom = list.map((promoIcon, index) => {
          return (
            <i
              key={index}
              class={['suiiconfont-critical', 'suiiconfont', promoIcon]}
            ></i>
          )
        })
        return showIconDom[0]
      } else {
        return null
      }
    })()

    return (
      <div
        class={[
          'fsp-element',
          'prices-info',
          {
            'prices-info_small-size': multiColumn,
            'prices-info__camel-price': showCamelPrice,
          },
          data.class,
          data.staticClass,
        ]}
        style={[data.style, data.staticStyle]}
      >
        <div class={['prices-info__content', ...suggestedSalePriceController.priceContentCls]}>
          <div class="content__sale-price-wrapper">
            {promotionIconListEl}
            {salePriceEl}
          </div>
          {suggestedSalePriceController.priceEl || retailPriceEl}
        </div>
      </div>
    )
  },
}
</script>

<style lang="less">
.prices-info {
  max-width: 100%;
  &__flash-label {
    .font-dpr(20px);
    color: @sui_color_discount;
    line-height: 0.32rem;
  }
  &__content {
    .flexbox();
    .align-center();
    .font-dpr(28px);

    direction: ltr /* rtl:ignore */;
    flex-wrap: wrap;

    // &_hide {
    //   height: 0.48rem;
    //   overflow: hidden;
    // }
    >span {
      .text-overflow();
    }
    
    &_row-susa {
      gap: 2px;
      align-items: baseline;
      flex-wrap: nowrap;

      .prices-info__suggested-sale-price {
        flex: 1;
        line-height: 14px;
        min-width: 0;
      }
    }
    &_column-susa {
      flex-direction: column;
      align-items: flex-start;

      .prices-info__suggested-sale-price {
        flex: 1;
        max-width: 100%;
        line-height: 14px;
        min-width: 0;
      }
    }
    .content {
      &__sale-price-wrapper {
        .flexbox();
        .align-center();
        min-width: 0;
      }
    }
  }

  >span {
    .text-overflow();
    &:first-of-type {
      flex-shrink: 0;
    }
  }
  [class*="iconfont"] {
    margin-right: 0.0533rem;
  }
  .sui_icon_flashsale_15px {
    .font-dpr(30px);
    color: @sui_color_flash;
    height: 0.5333rem;
    line-height: 0.5333rem;
  }
  .sui_icon_time_15px {
    .font-dpr(30px);
    color: @sui_color_discount;
    height: 0.5333rem;
    line-height: 0.5333rem;
  }
  &_small-size {
    .font-dpr(24px);
    line-height: 0.3733rem;
    .sui_icon_flashsale_15px, .sui_icon_time_15px  {
      height: 0.3733rem;
      line-height: 0.3733rem;
    }
  }
  &__unknown-price {
    .font-dpr(28px);
    display: block;
    width: 100%;
    color: #d53333;
    font-weight: bold;
  }
  &__sale-price {
    color: var(--product-card__sale-price-color, #222);
    font-weight: bold;
    &_promo {
      color: @sui_color_discount;
    }
    &_sheinclub {
      color: @sui_color_club_rosegold_dark1;
    }
  }
  &__origin-price {
    transform-origin: 0 0; // 为了scale缩放时对齐
    margin-left: 0.1067rem;
    align-self: end;

    text-decoration: line-through;
    font-size: .266rem;
    color: @sui_color_gray_light1; 
  }
  .product-card__discount {
    margin-left: 0.1067rem;
  }
  &__sheinclub-right {
    position: absolute;
    margin: 0;
  }
  &__camel-price {
    align-items: baseline;
  }
}
</style>
